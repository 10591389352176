/*doc
---
title: Card Relief
name: card-relief
category: Base
---

When information pieces need to be reviewed together, we sometimes group those
pieces and bring attention to them by using <code>.card-relief</code> and/or
<code>.medical-record-unit</code>. This is commonly seen in our references to a
patient's insurance.

```html_example
<div class="card-relief">
  <h4 class="medical-unit-heading">Primary Insurance</h4>
  <p class="medical-unit-detail">Humana (ppo)</p>
  <p class="medical-unit-detail">Policy #3574578</p>
</div>
```
*/

@use "sass:math";

.card {
  @media #{$only-non-phone-devices} {
    padding-left: $base-spacing;
    padding-right: $base-spacing;
    background-color: $white;
    border: ($base-border-width * 0.5) solid #d6d8d8;
    border-radius: $base-border-radius;
    box-shadow: $base-shadow;
  }
}

.card-header {
  align-items: flex-end;
  display: flex;
  padding-bottom: $small-spacing;
  border-bottom: $base-border;
  margin-bottom: $base-spacing;

  @media #{$only-non-phone-devices} {
    padding-top: ($base-spacing * 0.5);
    padding-right: $base-spacing;
    padding-left: $base-spacing;
    margin-left: -$base-spacing;
    margin-right: -$base-spacing;
  }

  + .required-label {
    margin-bottom: 8px;
    margin-top: -16px;
    position: relative;

    .required-instructions {
      font-size: $label-font-size;
      font-weight: $heavy-font-weight;
      margin: 0;
    }
  }
}

.card-relief {
  border: $base-border;
  border-radius: $base-border-radius;
  box-shadow: $base-shadow;
  margin-bottom: $base-spacing;
  padding: $base-spacing;

  &.push-right {
    margin-left: 5em;
    padding-left: 5em;
  }
}

.activations,
.admin-sessions-new,
.error-page,
.insurance_extension-ssos,
.patient_sessions,
.verified_patient_sessions {
  @media #{$only-non-phone-devices} {
    padding-top: 0;
  }

  .app-main {
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }

  .flash {
    margin-bottom: math.div($base-spacing, 1.5);
    margin-top: -(math.div($base-spacing, 1.5));
  }
}
