.location-branding {
  margin: 1em;
  text-align: center;
}

.location-image {
  @media #{$only-non-phone-devices} {
    max-height: 150px;
  }

  @media #{$only-small-screens} {
    max-height: 40px;
  }
}
