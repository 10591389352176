.form-actions {
  margin-top: $small-spacing;
  margin-bottom: $base-spacing;
  display: inline-block;
  position: relative;
  width: 100%;

  .button-primary {
    float: right;
    text-align: center;
  }

  .button-primary,
  .button-secondary {
    min-width: 180px;
  }

  &.centered {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $base-spacing;

    .button-primary,
    .skip {
      display: inline-flex;
      float: none;
      margin: 0 $base-spacing $base-spacing * 0.5 $base-spacing;
      min-width: 400px;
    }

    .button-small {
      min-width: 0px;
    }
  }

  .button-next::after {
    content: " \02014>";
  }
}
