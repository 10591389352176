.check-in-handoff {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 100%;

  .check-in-handoff-box {
    background-color: $white;
    padding: 2em 2.5em;
    text-align: center;
    width: 80%;

    @media screen and (max-width: 500px) {
      width: auto;
    }
  }

  .check-in-handoff-button {
    font-size: 0.947em;
    padding: 0.8em 3.5em;
  }

  .check-in-handoff-message {
    color: $dark-gray;
    font-weight: 600;
    margin-bottom: 1em;
    text-align: center;
  }
}
