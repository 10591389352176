label {
  color: $secondary-font-color;
  display: inline-block;
  font-size: $label-font-size;
  font-weight: $label-font-weight;
  letter-spacing: $label-letter-spacing;
  line-height: $label-line-height;
  margin-bottom: $base-spacing * 0.25;
}

abbr[title="required"],
abbr[title="requerido"] {
  border-bottom: none;
  text-decoration: none;
}

.field-unit,
.form-group {
  &:focus-within > label {
    color: $input-border-active-color;

    abbr[title="required"],
    abbr[title="requerido"] {
      color: inherit;
    }
  }
}

input,
select {
  color: $base-font-color;
  display: block;
  font-family: $base-font-family;
  font-size: $input-font-size;
  height: calc(1.4375em + 17px);
  line-height: normal;
}

[type="color"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
input:not([type]),
textarea {
  appearance: none;
  background-color: transparent;
  border-radius: $base-border-radius;
  border: $input-border;
  font-family: $base-font-family;
  font-size: $input-font-size;
  margin: 0;
  padding: $input-padding;
  transition: all 0.01s ease-in-out;
  width: 100%;

  &:hover {
    border-color: $input-border-hover-color;
    outline: none;
  }

  &:focus {
    border-color: $input-border-active-color;
    outline: 1px solid $input-border-active-color;
  }

  &:disabled {
    border-color: $input-disabled-color;
    color: $input-disabled-color;
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

textarea {
  height: $base-spacing * 6;
  resize: vertical;
}

$currency-icon-width: 0.5em;

.currency-input {
  label {
    position: relative;

    &::after {
      color: $base-font-color;
      content: "$";
      left: $small-spacing;
      padding: $small-spacing 0;
      position: absolute;
      top: 100%;
    }
  }

  input {
    padding-left: $small-spacing + $currency-icon-width;
  }
}

.text-label {
  font-size: $note-font-size;
  font-style: italic;
  font-weight: bold;
  text-transform: uppercase;
}

.text-value {
  color: $secondary-color;
  font-weight: bold;
}

.short-input {
  width: 50%;
}
