$app-main-vertical-gutters: $base-spacing * 2;
$step-icon-size: $base-font-size;

.patient_able_to_complete_screeners .sidebar {
  visibility: hidden;
}

.sidebar {
  min-height: calc(100vh - (#{$app-header-height} + #{$app-main-vertical-gutters}));
  width: calc(25% - (#{$base-spacing} / 2));
  margin: $base-spacing;
  margin-right: $base-spacing * 0.5;
  position: relative;

  .card-header {
    border-bottom-color: transparent;
    margin-bottom: 0;
  }

  .card-header-title {
    font-size: $scaled-down-font-size;
    font-weight: $heavy-font-weight;
    letter-spacing: 1px;
    margin: 0;
    text-transform: uppercase;
  }

  .sidebar-item {
    margin-right: -$base-spacing;
    margin-left: -$base-spacing;
    color: $secondary-font-color;
    display: block;
    padding: $small-spacing $base-spacing $small-spacing ($base-spacing + $step-icon-size + $small-spacing);
    position: relative;

    .step-icon {
      width: $step-icon-size;
      height: $step-icon-size;
      left: $base-spacing;
      margin-top: -$step-icon-size * 0.5;
      position: absolute;
      top: 50%;
    }

    &.is-active {
      background-color: $base-link-color;
      color: $white;

      &::after,
      &::before {
        width: 0;
      }
    }

    &.is-completed {
      color: $base-link-color;

      &:focus,
      &:hover {
        color: #248b78;
      }

      &::after,
      &::before {
        background: $primary-color;
      }
    }

    &::after,
    &::before {
      height: calc(50% - #{$step-icon-size} / 2 - #{$small-spacing} / 2);
      background: $iron;
      content: "";
      left: $base-spacing + $step-icon-size * 0.5;
      position: absolute;
      width: 1px;
    }

    &::after {
      bottom: 0;
    }

    &::before {
      top: 0;
    }
  }

  .check-in-navigation {
    a.sidebar-item,
    span.sidebar-item {
      &:first-of-type::before,
      &:last-of-type::after {
        width: 0;
      }
    }
  }

  .card-section-title {
    font-size: $h5-font-size;
    font-weight: $h5-font-weight;
    letter-spacing: $h5-letter-spacing;
  }
}
