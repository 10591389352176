body {
  -webkit-font-smoothing: antialiased;
  color: $base-font-color;
  font-family: $base-font-family;
  font-size: $base-font-size;
  font-weight: $base-font-weight;
  line-height: $base-line-height;

  @media screen and (min-width: 40em) {
    font-size: $base-font-size * 1.2;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: $base-font-size;
  line-height: 1.25;
  margin: 0;
}

p {
  font-size: $body1-font-size;
  font-weight: $body1-font-weight;
  letter-spacing: $body1-letter-spacing;
  margin: 0 0 $small-spacing;
}

a {
  -webkit-transition: color 0.1s linear;
  -moz-transition: color 0.1s linear;
  transition: color 0.1s linear;
  color: $base-link-color;
  text-decoration: none;

  &:hover {
    color: #269480;
  }

  &:active,
  &:focus {
    color: #269480;
    outline: none;
  }
}

hr {
  border-bottom: $base-border;
  border-left: none;
  border-right: none;
  border-top: none;
  margin: $base-spacing 0;
}

img,
picture {
  margin: 0;
  max-width: 100%;
}
