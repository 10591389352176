$nested-list-indent: $base-font-size;
$second-nested-list-indent: $nested-list-indent * 1.2;
$third-nested-list-indent: $nested-list-indent * 1.4;

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    padding-top: $base-spacing * 0.1;
    padding-bottom: $base-spacing * 0.1;
  }

  &.default-ul {
    list-style-type: disc;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }

  &.default-ol {
    list-style-type: decimal;
    margin-bottom: $small-spacing;
    padding-left: $base-spacing;
  }
}

dl {
  margin-bottom: $small-spacing;

  dt {
    font-weight: $heavy-font-weight;
    margin-top: $small-spacing;
  }

  dd {
    margin: 0;
  }
}

.nested-list {
  list-style-type: decimal;
  margin-left: $nested-list-indent;

  h3 {
    font-weight: bold;
  }

  .alpha-list {
    list-style-type: lower-alpha;
    margin-left: $second-nested-list-indent;

    .roman-list {
      list-style-type: lower-roman;
      margin-left: $third-nested-list-indent;
    }
  }
}

.unordered-list {
  list-style: none;
  padding-left: $default-spacing;

  li {
    position: relative;

    &::before {
      background-color: $primary-color;
      content: "";
      height: 7px;
      left: -$default-spacing;
      position: absolute;
      top: 12px;
      width: 7px;
    }
  }
}
