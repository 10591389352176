.image-group {
  width: 30%;

  img {
    border: $base-border;
    border-radius: $base-border-radius;
  }
}

.bad-photo {
  display: block;
  font-size: $note-font-size;
  margin-bottom: $small-spacing;
  text-align: right;
}

.retake-photo {
  color: $primary-color;
  display: inline;
}
