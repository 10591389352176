@use "sass:math";

$status-bar-height: 1.25em;
$app-header-height: 9em;
$epion-circle-size: 5em;
$epion-circle-size-small: math.div($epion-circle-size, 1.2);
$doctor-photo-size: 90px;

.app-header {
  align-items: center;
  display: flex;
  background-color: $primary-color;
  border-top: $status-bar-height solid $primary-color;
  box-shadow: $base-shadow;
  color: $white;
  height: $app-header-height;

  .photo-collage {
    width: calc(25% - (#{$base-spacing} / 2));
    padding-left: $base-spacing;
    padding-right: $base-spacing;
    height: $app-header-height - $status-bar-height;
    margin: $base-spacing;
    margin-right: $base-spacing * 0.5;
    position: relative;

    .doctor-photo {
      position: absolute;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      box-shadow: $base-shadow;
    }

    img {
      border-radius: 50%;
    }

    .doctor-photo {
      background-color: $white;
      margin-left: -(math.div($doctor-photo-size, 1.5));
      margin-top: -(math.div($doctor-photo-size, 1.5));
      z-index: 1;
      display: flex;

      img {
        margin: 4px;
      }
    }
  }

  .welcome-message {
    padding-left: $base-spacing;
    padding-right: $base-spacing;
    margin: $base-spacing;
    margin-left: $base-spacing * 0.5;
  }

  .appointment-details {
    font-size: $base-font-size;
    font-weight: $base-font-weight;
    margin: 0;
  }

  .patient-greeting {
    color: $white;
  }
}
