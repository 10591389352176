.app-main {
  min-height: calc(100vh - #{$app-header-height});
  display: flex;
  flex: 1;
}

.hidden {
  display: none !important;
}

.offset-right {
  margin-right: $base-spacing;
}
