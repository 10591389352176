$header-letter-spacing: 0.02em;

@mixin required() {
  color: inherit;
  padding-left: 3px;
}

.form-builder-value,
.form-render-value,
.textEditor-field .form-group.value-wrap,
.generate-pdf-result-form {
  display: none;
}

.attributes_table .row-consent_form {
  th,
  td {
    border-bottom: 0 none;
  }
}

.client-form {
  background: $white;
  margin-bottom: 20px;
  padding: 20px;

  h1 {
    background: none;
    color: $dark-gray;
    font-family: $serif;
    font-size: $title-font-size;
    letter-spacing: $header-letter-spacing;
    margin: 0;
    padding: 0;
  }

  p {
    display: block;
    margin: 0 0 $small-spacing;
  }

  input[attr-required="allRequired"] + label::after {
    @include required;
    content: "*";
  }

  .rendered-form,
  .form-wrap.form-builder {
    .form-control.ql-container {
      height: auto;
    }

    hr {
      border-bottom: $dashed-border;
      margin: $small-spacing 0;
    }

    .formbuilder,
    .fb {
      &-checkbox-group,
      &-radio-group {
        > label:empty {
          display: none;
        }

        .checkbox-inline,
        .checkbox,
        .radio-inline,
        .radio {
          input {
            margin: 0;
            position: absolute;
          }

          label {
            padding-left: 0;
          }
        }
      }
    }
  }

  .texteditor-field .field-label {
    display: none;
  }

  .prev-holder,
  .rendered-form {
    @import "../components/forms/checkable-inputs";
    @import "../components/forms/select";
    @import "../base/forms";

    .formbuilder,
    .fb {
      &-texteditor-label,
      &-divider-label {
        display: none;
      }
    }

    label {
      display: block;
      font-size: 1rem;
      font-weight: 400;
    }

    .form-group.invalid > label {
      color: $error-color;
    }
  }

  .radio-inline {
    display: inline-block;
    margin-right: 10px;
  }

  .button {
    display: inline-block;
  }

  .rendered-form .form-group {
    margin: 0;
    padding-top: 15px;
    position: relative;

    &.formbuilder,
    &.fb {
      &-divider {
        padding: 0;
      }
    }

    .formbuilder,
    .fb {
      &-required {
        @include required;
      }
    }
  }

  .formbuilder,
  .fb {
    &-textEditor {
      ul {
        list-style: none;
        padding-left: $default-spacing;

        li {
          position: relative;

          &::before {
            background-color: $primary-color;
            content: "";
            height: 7px;
            left: -$default-spacing;
            margin: -3px 0 0 0;
            position: absolute;
            top: 50%;
            width: 7px;
          }
        }
      }

      ol {
        list-style-type: decimal;
        padding-left: 0;

        li {
          list-style-position: inside;
        }
      }
    }
  }

  &.disabled {
    .error-message {
      display: none;
    }
  }
}

.app-main .client-form {
  padding: 0;
}

.admin_custom_consents {
  .prev-holder,
  .rendered-form {
    font-size: $mobile-base-font-size;
  }
}
