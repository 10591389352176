$header-letter-spacing: 0.02em;

.card-title {
  font-size: $h4-font-size;
  font-weight: $h4-font-weight;
  letter-spacing: $h4-letter-spacing;
  margin: 0;
}

.uppercase {
  text-transform: uppercase;
}
