@mixin panel-variant($border, $heading-text-color, $heading-bg-color, $heading-font-weight, $heading-border) {
  border-color: $border;

  .panel-heading {
    background-color: $heading-bg-color;
    border-color: $heading-border;
    color: $heading-text-color;
    font-weight: $heading-font-weight;
  }
}

.panel {
  background-color: $white;
  border: ($base-border-width * 0.5) solid transparent;
  border-radius: $base-border-radius;
  box-shadow: 0 1px 1px $shadow-black;
  margin-bottom: 20px;
}

.panel-body {
  &::after {
    clear: both;
    content: "";
    display: table;
  }
  padding: $panel-body-padding;
}

.panel-heading {
  border-top-left-radius: $tiny-border-radius;
  border-top-right-radius: $tiny-border-radius;
  padding: $panel-heading-padding;
}

.panel-footer {
  border-bottom-left-radius: $tiny-border-radius;
  border-bottom-right-radius: $tiny-border-radius;
  background-color: $panel-footer-bg;
  border-top: 1px solid $base-border-color;
  padding: $panel-footer-padding;
}

.panel-default {
  @include panel-variant(
    $base-border-color,
    $secondary-font-color,
    $light-gray,
    $heavy-font-weight,
    $base-border-color
  );
}

.panel-primary {
  @include panel-variant(
    $base-border-color,
    $panel-primary-heading-text-color,
    $secondary-color,
    $heavy-font-weight,
    $base-border-color
  );
}
