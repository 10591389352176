$success-check: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='18' viewBox='0 0 24 18'%3e%3ctitle%3echeck%3c/title%3e%3cdesc%3eCreated with Sketch.%3c/desc%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg fill='%234671A9'%3e%3cg%3e%3cpath d='M8.8 16.83c-.404.404-1.06.404-1.465 0L.54 10.033c-.792-.792-.713-2.125.237-2.81.745-.536 1.79-.378 2.44.272l4.123 4.122c.403.403 1.057.403 1.5 0L19.823.595c.65-.65 1.695-.808 2.44-.27.95.683 1 2.017.238 2.81L8.8 16.8'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";

.about-care-team {
  display: flex;
}

.about-doctor {
  flex: 0.5;
  display: inline-block;
  float: left;
  margin-right: $base-spacing;
}

.bio {
  flex: 1;
  display: inline-block;
  float: left;
}

.intake-reviewed {
  float: right;
  font-style: italic;

  &:after {
    width: $base-spacing;
    height: $base-font-size;
    background-image: url($success-check);
    background-repeat: no-repeat;
    content: "";
    display: inline-block;
    margin-left: $extra-small-spacing;
  }
}
