$alert-icon: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true' viewBox='0 0 24 24' data-testid='ErrorOutlineIcon'%3E%3Cpath fill='%23d32f2f' d='M11 15h2v2h-2zm0-8h2v6h-2zm.99-5C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z'%3E%3C/path%3E%3C/svg%3E";
$alert-icon-size: 1.5em;
$error-font-color: #572323;

.field-with-errors {
  [type="color"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="email"],
  [type="month"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="time"],
  [type="url"],
  [type="week"],
  input:not([type]),
  textarea {
    border-color: $error-color;

    &:hover {
      border: 1px solid $error-color;
      outline: none;
    }

    &:focus {
      border: 1px solid $error-color;
      outline: 1px solid $error-color;
    }
  }

  .styled-select select {
    border-color: $error-color;

    &:hover,
    &:focus {
      border: 1px solid $error-color;
      outline: none;
    }
  }

  .block-field-group {
    border-color: $error-color;

    > label {
      color: $error-color;
    }

    input {
      background-color: $input-background-color;
      border: $base-border;

      &:hover {
        border: $base-border;
        outline: none;
      }

      &:focus {
        border-color: $input-border-active-color;
        outline: 1px solid $input-border-active-color;
      }
    }

    .field-with-errors > input {
      border: 1px solid $error-color;

      &:hover {
        border: 1px solid $error-color;
        outline: none;
      }

      &:focus {
        border: 1px solid $error-color;
        outline: 1px solid $error-color;
      }
    }
  }

  > label,
  &:focus-within > label {
    color: $error-color;
  }

  .checkable-collection {
    margin-bottom: 0;
  }
}

%invalid-message {
  border: 1px solid $error-color;
  border-radius: $base-border-radius;
  color: $error-font-color;
  display: block;
  font-size: $label-font-size;
  height: 0;
  letter-spacing: 0.01563em;
  line-height: 1.3571;
  max-width: 100%;
  opacity: 0;
  overflow: hidden;
  padding: 14px 16px 14px 40px;
  position: absolute;
  transition: all 0.4s ease-in-out;

  &::before {
    background-image: url($alert-icon);
    background-size: contain;
    content: "";
    height: $alert-icon-size;
    left: $small-spacing;
    margin-top: -$alert-icon-size * 0.5;
    position: absolute;
    top: 50%;
    width: $alert-icon-size;
  }
}

%invalid-message-visible {
  height: auto;
  margin-bottom: $small-spacing;
  opacity: 1;
  position: relative;
}

%field-error-message {
  color: $error-color;
  font-weight: 400;
  font-size: 0.75rem;
  height: 0;
  letter-spacing: 0.025em;
  line-height: 1.66;
  text-align: left;
  margin: 4px 0 0 0;
  opacity: 0;
  overflow: hidden;
}

%field-error-message-for-input {
  display: block;
  margin: 4px 14px 0 14px;
}

%field-error-message-visible {
  height: auto;
  opacity: 1;
}

.form-error-message-visible,
.field-error {
  @extend %invalid-message;
  @extend %invalid-message-visible;
}

.field-error-message {
  @extend %field-error-message;
  @extend %field-error-message-visible;
}

.field-error-message-for-input {
  @extend %field-error-message-for-input;
}

form {
  .form-error-message {
    @extend %invalid-message;
  }

  .formbuilder-checkbox-group,
  .formbuilder-radio-group,
  .formbuilder-text,
  .formbuilder-number,
  .formbuilder-select,
  .field-unit.select,
  .field-unit.string,
  .field-unit.email,
  .field-unit.boolean,
  .checkable-collection {
    position: relative;

    &.invalid {
      > label {
        color: $error-color;
      }
    }

    .error-message {
      @extend %field-error-message;
    }
  }

  .formbuilder-text,
  .formbuilder-number,
  .formbuilder-select,
  .field-unit.select,
  .field-unit.string,
  .field-unit.email {
    .error-message {
      @extend %field-error-message-for-input;
    }
  }

  &.invalid .form-error-message {
    @extend %invalid-message-visible;
  }

  &.validated {
    .formbuilder-checkbox-group,
    .formbuilder-radio-group,
    .formbuilder-text,
    .formbuilder-number,
    .formbuilder-select,
    .field-unit.select,
    .field-unit.string,
    .field-unit.email,
    .field-unit.boolean,
    .checkable-collection {
      &.invalid .error-message {
        @extend %field-error-message-visible;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      input[type="number"] {
        &:invalid,
        &.invalid {
          border-color: $error-color;

          &:focus {
            outline: 1px solid $error-color;
          }
        }
      }

      &.invalid .styled-select select {
        border-color: $error-color;
      }
    }
  }
}
