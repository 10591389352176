// Typography
$helvetica: "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$georgia: "Georgia", "Times", "Times New Roman", serif;
$sans-serif: var(--primary-font), $helvetica;
$serif: var(--secondary-font), $georgia;

$base-font-family: $sans-serif;

// Line height
$base-line-height: 1.4;

// Other sizes
$default-spacing: 1em;
$base-spacing: $base-line-height * $default-spacing;
$small-spacing: $base-spacing * 0.5;
$extra-small-spacing: $small-spacing * 0.5;

// Font sizes
$base-font-size: 1em;
$input-font-size: 16px;
$scaled-down-font-size: 0.833em;
$mobile-base-font-size: $base-font-size * 1.2;
$note-font-size: 0.75em;
$title-font-size: 1.44em;
$base-font-weight: 400;
$heavy-font-weight: 700;
$notice-font-size: $base-font-size * 0.8;

// Media Queries
$phone-max-width: 767px;
$non-phone-min-width: $phone-max-width + 1;
$only-non-phone-devices: "only screen and (min-width: #{$non-phone-min-width})";
$only-small-screens: "only screen and (max-width: #{$phone-max-width})";

// Colors
$primary-color: var(--primary-color);
$secondary-color: var(--secondary-check-in-color);

$orange: #fd6f32;
$pink: #d32f2f;
$blue: #4671a9;
$caring-blue: #23315D;

$black: #000000;
$shadow-black: rgba($black, 0.05);
$dark-gray: #333a3f;
$medium-gray: #666b6f;
$light-gray: #fafafa;
$white: #fff;
$iron: #dbdddd;
$header-white: rgba($white, 0.6);
$error-color: $pink;

$base-background-color: $light-gray;
$input-background-color: #f7f8f8;
$input-disabled-color: rgba(0, 0, 0, 0.26);
$button-hover-color: #4e82bb;

// Font colors
$base-font-color: var(--palette-text-primary, #1a1a1a);
$secondary-font-color: var(--palette-text-secondary);
$base-link-color: $primary-color;
$panel-primary-heading-text-color: $header-white;

// Border
$base-border-color: $iron;
$base-border-width: 1px;
$base-border: $base-border-width solid $base-border-color;
$base-border-radius: 4px;
$dashed-border: 1px dashed $base-border-color;
$input-border-color: rgba(0, 0, 0, 0.23);
$input-border-hover-color: var(--palette-text-primary);
$input-border-active-color: $primary-color;
$input-border: 1px solid $input-border-color;
$input-padding: 8.5px 14px;
$tiny-border-radius: 3px;

// Shadow
$base-shadow: 0 3px 20px 0 rgba(#000, 0.1);

// Inputs
$collection-indent: $base-spacing * 1.5;

// SVG
$graph-height: $base-font-size * 12;
$stroke-width: 1.5px;

// Animations
$base-transition: all 0.2s ease-in-out;

// Panels
$panel-heading-padding: 10px 15px;
$panel-footer-padding: $panel-heading-padding;
$panel-footer-bg: #f7f8f8;
$panel-body-padding: 15px;

// Grid
$max-width: 48em;

// Previsit MUI styles
$body1-font-size: 1rem;
$body1-font-weight: 400;
$body1-letter-spacing: 0.03125em;
$field-hint-font-size: 0.75rem;
$field-hint-font-weight: 400;
$field-hint-letter-spacing: 0.03333em;
$field-hint-line-height: 1.66;
$field-hint-margin-left: 0.75em;
$field-hint-margin-top: 0.25em;
$h4-font-size: 2.125rem;
$h4-font-weight: 600;
$h4-letter-spacing: 0.01563em;
$h5-font-size: 1.5rem;
$h5-font-weight: 600;
$h5-letter-spacing: 0em;
$h6-font-size: 1.1875rem;
$h6-font-weight: 600;
$h6-letter-spacing: 0.00938em;
$label-font-size: 0.875rem;
$label-font-weight: 700;
$label-letter-spacing: 0.00938em;
$label-line-height: 1.43;
