@use "sass:math";

@function strip-units($value) {
  @return math.div($value, $value * 0 + 1);
}

$checkbox-off: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3echeckbox-input%3c/title%3e%3cdesc%3eNo hover when checkbox off%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='4'/%3e%3cpath d='M0 4.01C0 1.79 1.79 0 4.01 0h17.98C24.21 0 26 1.79 26 4.01v17.98c0 2.22-1.79 4.01-4.01 4.01H4.01C1.79 26 0 24.21 0 21.99V4.01zm2-.02C2 2.89 2.89 2 3.99 2h18.02c1.1 0 1.99.89 1.99 1.99v18.02c0 1.1-.89 1.99-1.99 1.99H3.99C2.89 24 2 23.11 2 22.01V3.99z' id='b'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse fill='%23F7F8F8' xlink:href='%23a'/%3e%3cuse xlink:href='%23a'/%3e%3c/g%3e%3cg%3e%3cuse fill='%23DADCDC' xlink:href='%23b'/%3e%3cuse xlink:href='%23b'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$checkbox-off-hover: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3echeckbox-input%3c/title%3e%3cdesc%3eOn hover when checkbox off%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='4'/%3e%3cpath d='M0 4.01C0 1.79 1.79 0 4.01 0h17.98C24.21 0 26 1.79 26 4.01v17.98c0 2.22-1.79 4.01-4.01 4.01H4.01C1.79 26 0 24.21 0 21.99V4.01zm2-.02C2 2.89 2.89 2 3.99 2h18.02c1.1 0 1.99.89 1.99 1.99v18.02c0 1.1-.89 1.99-1.99 1.99H3.99C2.89 24 2 23.11 2 22.01V3.99z' id='b'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse fill='%23F7F8F8' xlink:href='%23a'/%3e%3cuse xlink:href='%23a'/%3e%3c/g%3e%3cg%3e%3cuse xlink:href='%23b'/%3e%3cuse fill='%234671A9' xlink:href='%23b'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$checkbox-on: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3echeckbox-input%3c/title%3e%3cdesc%3eOn hover when checkbox on%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='4'/%3e%3cpath d='M7.44 14.12c-.59-.57-.59-1.49 0-2.06.61-.6 1.56-.6 2.15-.02l1.81 1.76 5.01-4.87c.59-.57 1.54-.57 2.13 0 .61.59.61 1.52.02 2.09l-6.32 6.14c-.46.45-1.21.45-1.67 0l-3.13-3.04zM0 4.01C0 1.79 1.79 0 4.01 0h17.98C24.21 0 26 1.79 26 4.01v17.98c0 2.22-1.79 4.01-4.01 4.01H4.01C1.79 26 0 24.21 0 21.99V4.01zm2-.02C2 2.89 2.89 2 3.99 2h18.02c1.1 0 1.99.89 1.99 1.99v18.02c0 1.1-.89 1.99-1.99 1.99H3.99C2.89 24 2 23.11 2 22.01V3.99z' id='b'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse xlink:href='%23a'/%3e%3cuse fill-opacity='.2' fill='%234671A9' xlink:href='%23a'/%3e%3c/g%3e%3cg%3e%3cuse xlink:href='%23b'/%3e%3cuse fill='%234671A9' xlink:href='%23b'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$checkbox-on-hover: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3echeckbox-input%3c/title%3e%3cdesc%3eOn hover when checkbox on%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='4'/%3e%3cpath d='M7.44 14.12c-.59-.57-.59-1.49 0-2.06.61-.6 1.56-.6 2.15-.02l1.81 1.76 5.01-4.87c.59-.57 1.54-.57 2.13 0 .61.59.61 1.52.02 2.09l-6.32 6.14c-.46.45-1.21.45-1.67 0l-3.13-3.04zM0 4.01C0 1.79 1.79 0 4.01 0h17.98C24.21 0 26 1.79 26 4.01v17.98c0 2.22-1.79 4.01-4.01 4.01H4.01C1.79 26 0 24.21 0 21.99V4.01zm2-.02C2 2.89 2.89 2 3.99 2h18.02c1.1 0 1.99.89 1.99 1.99v18.02c0 1.1-.89 1.99-1.99 1.99H3.99C2.89 24 2 23.11 2 22.01V3.99z' id='b'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse xlink:href='%23a'/%3e%3cuse fill-opacity='.2' fill='%234671A9' xlink:href='%23a'/%3e%3c/g%3e%3cg%3e%3cuse xlink:href='%23b'/%3e%3cuse fill='%234671A9' xlink:href='%23b'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$checkbox-on-disabled: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3echeckbox-input%3c/title%3e%3cdesc%3eOn hover when checkbox on%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='4'/%3e%3cpath d='M7.44 14.12c-.59-.57-.59-1.49 0-2.06.61-.6 1.56-.6 2.15-.02l1.81 1.76 5.01-4.87c.59-.57 1.54-.57 2.13 0 .61.59.61 1.52.02 2.09l-6.32 6.14c-.46.45-1.21.45-1.67 0l-3.13-3.04zM0 4.01C0 1.79 1.79 0 4.01 0h17.98C24.21 0 26 1.79 26 4.01v17.98c0 2.22-1.79 4.01-4.01 4.01H4.01C1.79 26 0 24.21 0 21.99V4.01zm2-.02C2 2.89 2.89 2 3.99 2h18.02c1.1 0 1.99.89 1.99 1.99v18.02c0 1.1-.89 1.99-1.99 1.99H3.99C2.89 24 2 23.11 2 22.01V3.99z' id='b'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse xlink:href='%23a'/%3e%3cuse fill-opacity='.2' fill='%23CACCCC' xlink:href='%23a'/%3e%3c/g%3e%3cg%3e%3cuse xlink:href='%23b'/%3e%3cuse fill='%23CACCCC' xlink:href='%23b'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$radio-off: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3eradio-input%3c/title%3e%3cdesc%3eNo hover when radio off%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='40'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse fill='%23F7F8F8' xlink:href='%23a'/%3e%3cuse xlink:href='%23a'/%3e%3c/g%3e%3cpath d='M0 13C0 5.82 5.82 0 13 0s13 5.82 13 13-5.82 13-13 13S0 20.18 0 13zm2 0C2 6.92 6.92 2 13 2s11 4.92 11 11-4.92 11-11 11S2 19.08 2 13z' fill='%23DADCDC'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$radio-off-hover: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3eradio-input%3c/title%3e%3cdesc%3eOn hover when radio off%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='40'/%3e%3cpath d='M0 13C0 5.82 5.82 0 13 0s13 5.82 13 13-5.82 13-13 13S0 20.18 0 13zm2 0C2 6.92 6.92 2 13 2s11 4.92 11 11-4.92 11-11 11S2 19.08 2 13z' id='b'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse fill='%23F7F8F8' xlink:href='%23a'/%3e%3cuse xlink:href='%23a'/%3e%3c/g%3e%3cg%3e%3cuse xlink:href='%23b'/%3e%3cuse fill='%234671A9' xlink:href='%23b'/%3e%3c/g%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$radio-on: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3eradio-input%3c/title%3e%3cdesc%3eNo hover when radio on%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='40'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse fill='%23F7F8F8' xlink:href='%23a'/%3e%3cuse xlink:href='%23a'/%3e%3c/g%3e%3cpath d='M5 13c0-4.42 3.59-8 8-8 4.42 0 8 3.59 8 8 0 4.42-3.59 8-8 8-4.42 0-8-3.59-8-8zm-5 0C0 5.82 5.82 0 13 0s13 5.82 13 13-5.82 13-13 13S0 20.18 0 13zm2 0C2 6.92 6.92 2 13 2s11 4.92 11 11-4.92 11-11 11S2 19.08 2 13z' fill='%234671A9'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$radio-on-hover: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3eradio-input%3c/title%3e%3cdesc%3eOn hover when radio on%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='40'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse xlink:href='%23a'/%3e%3cuse fill-opacity='.2' fill='%234671A9' xlink:href='%23a'/%3e%3c/g%3e%3cpath d='M5 13c0-4.42 3.59-8 8-8 4.42 0 8 3.59 8 8 0 4.42-3.59 8-8 8-4.42 0-8-3.59-8-8zm-5 0C0 5.82 5.82 0 13 0s13 5.82 13 13-5.82 13-13 13S0 20.18 0 13zm2 0C2 6.92 6.92 2 13 2s11 4.92 11 11-4.92 11-11 11S2 19.08 2 13z' fill='%234671A9'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$radio-on-disabled: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='26' height='26' viewBox='0 0 26 26'%3e%3ctitle%3eradio-input%3c/title%3e%3cdesc%3eNo hover when radio on%3c/desc%3e%3cdefs%3e%3crect id='a' width='26' height='26' rx='40'/%3e%3c/defs%3e%3cg fill='none' fill-rule='evenodd'%3e%3cg%3e%3cg%3e%3cuse fill='%23F7F8F8' xlink:href='%23a'/%3e%3cuse xlink:href='%23a'/%3e%3c/g%3e%3cpath d='M5 13c0-4.42 3.59-8 8-8 4.42 0 8 3.59 8 8 0 4.42-3.59 8-8 8-4.42 0-8-3.59-8-8zm-5 0C0 5.82 5.82 0 13 0s13 5.82 13 13-5.82 13-13 13S0 20.18 0 13zm2 0C2 6.92 6.92 2 13 2s11 4.92 11 11-4.92 11-11 11S2 19.08 2 13z' fill='%23CACCCC'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-dissatisfied: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23929292' id='icon-7-sad-face'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M12,15 C12.5522848,15 13,14.5522848 13,14 C13,13.4477152 12.5522848,13 12,13 C11.4477152,13 11,13.4477152 11,14 C11,14.5522848 11.4477152,15 12,15 L12,15 Z M21,15 C21.5522848,15 22,14.5522848 22,14 C22,13.4477152 21.5522848,13 21,13 C20.4477152,13 20,13.4477152 20,14 C20,14.5522848 20.4477152,15 21,15 L21,15 Z M16.4813232,21 C13,21 11,23 11,23 L11,22 C11,22 13,20 16.4813232,20 C19.9626465,20 22,22 22,22 L22,23 C22,23 19.9626465,21 16.4813232,21 L16.4813232,21 Z' id='sad-face'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-dissatisfied-selected: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23ffffff' id='icon-7-sad-face'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M12,15 C12.5522848,15 13,14.5522848 13,14 C13,13.4477152 12.5522848,13 12,13 C11.4477152,13 11,13.4477152 11,14 C11,14.5522848 11.4477152,15 12,15 L12,15 Z M21,15 C21.5522848,15 22,14.5522848 22,14 C22,13.4477152 21.5522848,13 21,13 C20.4477152,13 20,13.4477152 20,14 C20,14.5522848 20.4477152,15 21,15 L21,15 Z M16.4813232,21 C13,21 11,23 11,23 L11,22 C11,22 13,20 16.4813232,20 C19.9626465,20 22,22 22,22 L22,23 C22,23 19.9626465,21 16.4813232,21 L16.4813232,21 Z' id='sad-face'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-neutral: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23929292' id='icon-5-neutral-face'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M12,14 C12.5522848,14 13,13.5522848 13,13 C13,12.4477152 12.5522848,12 12,12 C11.4477152,12 11,12.4477152 11,13 C11,13.5522848 11.4477152,14 12,14 L12,14 Z M21,14 C21.5522848,14 22,13.5522848 22,13 C22,12.4477152 21.5522848,12 21,12 C20.4477152,12 20,12.4477152 20,13 C20,13.5522848 20.4477152,14 21,14 L21,14 Z M12,21 L12,22 L21,22 L21,21 L12,21 L12,21 Z' id='neutral-face'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-neutral-selected: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23ffffff' id='icon-5-neutral-face'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M12,14 C12.5522848,14 13,13.5522848 13,13 C13,12.4477152 12.5522848,12 12,12 C11.4477152,12 11,12.4477152 11,13 C11,13.5522848 11.4477152,14 12,14 L12,14 Z M21,14 C21.5522848,14 22,13.5522848 22,13 C22,12.4477152 21.5522848,12 21,12 C20.4477152,12 20,12.4477152 20,13 C20,13.5522848 20.4477152,14 21,14 L21,14 Z M12,21 L12,22 L21,22 L21,21 L12,21 L12,21 Z' id='neutral-face'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-satisfied: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23929292' id='icon-6-smiling-face'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M12,14 C12.5522848,14 13,13.5522848 13,13 C13,12.4477152 12.5522848,12 12,12 C11.4477152,12 11,12.4477152 11,13 C11,13.5522848 11.4477152,14 12,14 L12,14 Z M21,14 C21.5522848,14 22,13.5522848 22,13 C22,12.4477152 21.5522848,12 21,12 C20.4477152,12 20,12.4477152 20,13 C20,13.5522848 20.4477152,14 21,14 L21,14 Z M16.4813232,22 C13,22 11,20 11,20 L11,21 C11,21 13,23 16.4813232,23 C19.9626465,23 22,21 22,21 L22,20 C22,20 19.9626465,22 16.4813232,22 L16.4813232,22 Z' id='smiling-face'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-satisfied-selected: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23ffffff' id='icon-6-smiling-face'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M12,14 C12.5522848,14 13,13.5522848 13,13 C13,12.4477152 12.5522848,12 12,12 C11.4477152,12 11,12.4477152 11,13 C11,13.5522848 11.4477152,14 12,14 L12,14 Z M21,14 C21.5522848,14 22,13.5522848 22,13 C22,12.4477152 21.5522848,12 21,12 C20.4477152,12 20,12.4477152 20,13 C20,13.5522848 20.4477152,14 21,14 L21,14 Z M16.4813232,22 C13,22 11,20 11,20 L11,21 C11,21 13,23 16.4813232,23 C19.9626465,23 22,21 22,21 L22,20 C22,20 19.9626465,22 16.4813232,22 L16.4813232,22 Z' id='smiling-face'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-very-dissatisfied: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23929292' id='icon-20-sad-face-eyebrows'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M16.4813232,21 C13,21 11,23 11,23 L11,22 C11,22 13,20 16.4813232,20 C19.9626465,20 22,22 22,22 L22,23 C22,23 19.9626465,21 16.4813232,21 L16.4813232,21 Z M20.6215213,9.96709541 L20.0623284,10.796133 L23.3784787,13.0329046 L23.9376716,12.203867 L20.6215213,9.96709541 L20.6215213,9.96709541 Z M9.0623284,12.203867 L9.62152131,13.0329046 L12.9376716,10.796133 L12.3784787,9.96709541 L9.0623284,12.203867 L9.0623284,12.203867 Z M12,15 C12.5522848,15 13,14.5522848 13,14 C13,13.4477152 12.5522848,13 12,13 C11.4477152,13 11,13.4477152 11,14 C11,14.5522848 11.4477152,15 12,15 L12,15 Z M21,15 C21.5522848,15 22,14.5522848 22,14 C22,13.4477152 21.5522848,13 21,13 C20.4477152,13 20,13.4477152 20,14 C20,14.5522848 20.4477152,15 21,15 L21,15 Z' id='sad-face-eyebrows'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-very-dissatisfied-selected: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23ffffff' id='icon-20-sad-face-eyebrows'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M16.4813232,21 C13,21 11,23 11,23 L11,22 C11,22 13,20 16.4813232,20 C19.9626465,20 22,22 22,22 L22,23 C22,23 19.9626465,21 16.4813232,21 L16.4813232,21 Z M20.6215213,9.96709541 L20.0623284,10.796133 L23.3784787,13.0329046 L23.9376716,12.203867 L20.6215213,9.96709541 L20.6215213,9.96709541 Z M9.0623284,12.203867 L9.62152131,13.0329046 L12.9376716,10.796133 L12.3784787,9.96709541 L9.0623284,12.203867 L9.0623284,12.203867 Z M12,15 C12.5522848,15 13,14.5522848 13,14 C13,13.4477152 12.5522848,13 12,13 C11.4477152,13 11,13.4477152 11,14 C11,14.5522848 11.4477152,15 12,15 L12,15 Z M21,15 C21.5522848,15 22,14.5522848 22,14 C22,13.4477152 21.5522848,13 21,13 C20.4477152,13 20,13.4477152 20,14 C20,14.5522848 20.4477152,15 21,15 L21,15 Z' id='sad-face-eyebrows'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-very-satisfied: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23929292' id='icon-11-laughing-face'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M12,14 C12.5522848,14 13,13.5522848 13,13 C13,12.4477152 12.5522848,12 12,12 C11.4477152,12 11,12.4477152 11,13 C11,13.5522848 11.4477152,14 12,14 L12,14 Z M21,14 C21.5522848,14 22,13.5522848 22,13 C22,12.4477152 21.5522848,12 21,12 C20.4477152,12 20,12.4477152 20,13 C20,13.5522848 20.4477152,14 21,14 L21,14 Z M9,19 C9,19 10,25 16.5,25 C23,25 24,19 24,19 L9,19 L9,19 Z M10.3446045,20 C10.3446045,20 11.632034,24.0134284 16.5246353,24.0134277 C21.4172366,24.0134273 22.642334,20 22.642334,20 L10.3446045,20 L10.3446045,20 Z' id='laughing-face'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$emoji-very-satisfied-selected: "data:image/svg+xml;charset=UTF-8,%3csvg height='32px' version='1.1' viewBox='0 0 32 32' width='32px' xmlns='http://www.w3.org/2000/svg' xmlns:sketch='http://www.bohemiancoding.com/sketch/ns' xmlns:xlink='http://www.w3.org/1999/xlink'%3e%3ctitle/%3e%3cdesc/%3e%3cdefs/%3e%3cg fill='none' fill-rule='evenodd' id='Page-1' stroke='none' stroke-width='1'%3e%3cg fill='%23ffffff' id='icon-11-laughing-face'%3e%3cpath d='M16.5,29 C23.4035597,29 29,23.4035597 29,16.5 C29,9.59644029 23.4035597,4 16.5,4 C9.59644029,4 4,9.59644029 4,16.5 C4,23.4035597 9.59644029,29 16.5,29 L16.5,29 Z M16.5,28 C22.8512749,28 28,22.8512749 28,16.5 C28,10.1487251 22.8512749,5 16.5,5 C10.1487251,5 5,10.1487251 5,16.5 C5,22.8512749 10.1487251,28 16.5,28 L16.5,28 Z M12,14 C12.5522848,14 13,13.5522848 13,13 C13,12.4477152 12.5522848,12 12,12 C11.4477152,12 11,12.4477152 11,13 C11,13.5522848 11.4477152,14 12,14 L12,14 Z M21,14 C21.5522848,14 22,13.5522848 22,13 C22,12.4477152 21.5522848,12 21,12 C20.4477152,12 20,12.4477152 20,13 C20,13.5522848 20.4477152,14 21,14 L21,14 Z M9,19 C9,19 10,25 16.5,25 C23,25 24,19 24,19 L9,19 L9,19 Z M10.3446045,20 C10.3446045,20 11.632034,24.0134284 16.5246353,24.0134277 C21.4172366,24.0134273 22.642334,20 22.642334,20 L10.3446045,20 L10.3446045,20 Z' id='laughing-face'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$checkable-input-size: $base-font-size * 1.4;
$radio-block-min-height: $base-font-size * 4;

// Reused margins
$checkable-input-margin-left: $base-spacing * 1.5;
$column-margin-right: $base-spacing;

// Dynamically calculate column spacing
$input-space: $checkable-input-size + $checkable-input-margin-left;
$non-text-width: $input-space * 2 + $column-margin-right;
$column-width: (100% - strip-units($non-text-width)) * 0.5;

.checkbox,
.radio {
  display: block;
  margin-bottom: $small-spacing;

  label {
    font-size: 1rem;
    font-weight: 400;

    &:hover {
      cursor: pointer;
    }
  }
}

input[type="checkbox"],
input[type="radio"] {
  height: $checkable-input-size;
  width: $checkable-input-size;
  cursor: pointer;
  border: 0;
  visibility: hidden;
  outline: 0;
  overflow: hidden;
  margin: 0;
  padding: 0;
  position: absolute;
}

input[type="checkbox"] + label::before,
input[type="radio"] + label::before {
  height: $checkable-input-size;
  width: $checkable-input-size;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  display: inline-block;
  margin-right: math.div($small-spacing, 1.5);
  vertical-align: top;
}

input[type="checkbox"] + label::before {
  background-image: url($checkbox-off);
}

input[type="checkbox"]:focus + label::before,
input[type="checkbox"]:hover + label::before {
  background-image: url($checkbox-off-hover);
}

input[type="checkbox"]:disabled + label::before {
  background-image: url($checkbox-off);
}

input[type="checkbox"]:checked + label::before {
  background-image: url($checkbox-on);
}

input[type="checkbox"]:checked:focus + label::before,
input[type="checkbox"]:checked:hover + label::before {
  background-image: url($checkbox-on-hover);
}

input[type="checkbox"]:checked:disabled + label::before {
  background-image: url($checkbox-on-disabled);
}

input[type="radio"] + label::before {
  background-image: url($radio-off);
}

input[type="radio"]:focus + label::before,
input[type="radio"]:hover + label::before {
  background-image: url($radio-off-hover);
}

input[type="radio"]:disabled + label::before {
  background-image: url($radio-off);
}

input[type="radio"]:checked + label::before {
  background-image: url($radio-on);
}

input[type="radio"]:checked:focus + label::before,
input[type="radio"]:checked:hover + label::before {
  background-image: url($radio-on-hover);
}

input[type="radio"]:checked:disabled + label::before {
  background-image: url($radio-on-disabled);
}

.checkable-collection,
.field-unit.boolean {
  margin: $small-spacing 0;
}

.field-unit {
  .checkable-collection {
    .radio:last-child {
      margin-bottom: 0;
    }
  }
}

.checkbox {
  &.hightlighted {
    background-color: $base-background-color;
    border: ($base-border-width * 0.5) solid $primary-color;
    border-radius: $base-border-radius;
    padding: $base-spacing * 0.5;

    label {
      margin: 0;
    }
  }
}

.column-inputs {
  &.check_boxes {
    width: 103%;

    .field-unit {
      width: $column-width;
    }
  }

  &.radio_buttons {
    .checkable-collection {
      display: flex;
    }

    .field-unit {
      flex: 1;
    }
  }

  > label {
    display: block;
  }

  .field-unit {
    display: inline-block;
    vertical-align: top;

    label {
      margin-right: $column-margin-right;
      max-width: 100%;
      word-wrap: break-word;
    }
  }
}

.radio-block-group {
  input[type="radio"] + label::before {
    display: none;
  }

  .checkable-collection {
    display: flex;
    background-color: $input-background-color;
    border: $base-border;
    border-radius: $base-border-radius;
    margin: 0;

    .field-unit.radio {
      border-right: $base-border;
      margin: 0;

      input[type="radio"]:checked + label {
        background-color: $primary-color;
        color: $white;
        font-weight: $heavy-font-weight;
        height: 100%;
      }

      &:last-child input[type="radio"]:checked + label {
        border-radius: 0 ($base-border-radius * 0.5) ($base-border-radius * 0.5) 0;
      }

      &:nth-child(2) input[type="radio"]:checked + label {
        border-radius: ($base-border-radius * 0.5) 0 0 ($base-border-radius * 0.5);
      }

      &.readonly input[type="radio"]:checked + label {
        opacity: 0.6;

        label {
          color: $white;
          font-weight: $heavy-font-weight;
        }
      }

      &:last-child {
        border-right: 0;
      }
    }

    label {
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 0 $small-spacing;
      font-size: $note-font-size;
      margin: 0;
      min-height: $radio-block-min-height;
      text-align: center;
    }
  }

  &.with-emojis .field-unit.radio {
    label {
      background-position: center $extra-small-spacing;
      background-repeat: no-repeat;
      padding: ($base-spacing * 2) $small-spacing $small-spacing;
    }

    &:nth-child(1) label {
      background-image: url($emoji-very-dissatisfied);
    }

    &:nth-child(1) input[type="radio"]:checked + label {
      background-image: url($emoji-very-dissatisfied-selected);
    }

    &:nth-child(2) label {
      background-image: url($emoji-dissatisfied);
    }

    &:nth-child(2) input[type="radio"]:checked + label {
      background-image: url($emoji-dissatisfied-selected);
    }

    &:nth-child(3) label {
      background-image: url($emoji-neutral);
    }

    &:nth-child(3) input[type="radio"]:checked + label {
      background-image: url($emoji-neutral-selected);
    }

    &:nth-child(4) label {
      background-image: url($emoji-satisfied);
    }

    &:nth-child(4) input[type="radio"]:checked + label {
      background-image: url($emoji-satisfied-selected);
    }

    &:nth-child(5) label {
      background-image: url($emoji-very-satisfied);
    }

    &:nth-child(5) input[type="radio"]:checked + label {
      background-image: url($emoji-very-satisfied-selected);
    }
  }
}

.chips {
  display: flex;
  flex-wrap: wrap;

  .field-unit {
    flex: 0 0 auto;
    margin: 0 $small-spacing 0 0;
  }

  input[type="checkbox"] {
    + label {
      border: 2px solid $primary-color;
      border-radius: 50px;
      color: $primary-color;
      cursor: pointer;
      display: inline-block;
      font-size: $base-font-size;
      padding: 3px 30px 3px 10px;
      transition: $base-transition;
      user-select: none;

      &::before {
        background-image: none;
        margin: 0;
        width: 0;
      }

      &::after {
        content: "+";
        position: absolute;
        right: $small-spacing;
      }
    }

    &:checked + label {
      background-color: $primary-color;
      color: $white;
      transition: $base-transition;

      &::after {
        content: "✓";
      }
    }

    &:disabled + label {
      opacity: 0.3;
    }
  }
}

.checkable-collection-form-section.form-section {
  margin-bottom: 2em;

  & > .field-unit {
    margin-bottom: 0;
  }
}

.checkable-collection-hints.field-hint {
  display: flex;
  justify-content: space-between;
}
