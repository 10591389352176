.finding-group-header {
  color: $secondary-font-color;
  margin: $base-spacing 0 $small-spacing 0;
}

.finding-field-group {
  align-items: center;
  display: flex;
  flex-wrap: wrap;

  .field-unit {
    align-items: center;
    display: flex;
    flex: 0 0 auto;
    margin-bottom: 0;
    margin-top: 0;

    &.boolean {
      max-width: 100%;
    }

    .checkbox {
      margin: 0;
    }

    &:not(.boolean) {
      margin: 0 $small-spacing 0 0;

      label {
        margin: 0 $small-spacing 0 0;
        white-space: nowrap;
      }
    }
  }

  label {
    margin-bottom: 0;
    margin-top: 0;
  }

  label ~ .field-unit,
  .field-unit.with-label ~ .field-unit {
    margin-left: $small-spacing;
  }
}

.finding-fields {
  display: flex;
  flex-flow: row wrap;

  .finding-field-group {
    flex-basis: 50%;
  }

  .styled-select {
    margin: $small-spacing 0;
    width: 180px;

    @media (max-width: 768px) {
      width: 120px;
    }
  }
}

.field-unit.with-option-lists .checkbox label::before {
  display: none;
}
