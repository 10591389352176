/*doc
---
title: Notice
name: notice
category: Base
---

We use notices to get the attention of the user to specific situations or ways
to fix issues. Notices are smaller than the regular text to show that they are
not as important.

```html_example
<p class="notice">
  Roads? Where we are going we don't need roads.
</p>
```
*/

.notice {
  color: $pink;
  font-size: $notice-font-size;
  margin-bottom: $base-spacing;
  margin-top: $base-spacing;
}
