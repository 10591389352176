@use "sass:math";

@mixin flash($color) {
  border: 2px solid $color;
  border-radius: $base-border-radius;
  display: block;
  font-weight: $base-font-weight;
  margin-bottom: $base-spacing;
  padding: math.div($base-spacing, 3.5) math.div($base-spacing, 2.5);
}

.flash-alert {
  @include flash($orange);
  color: #e4642d;
}

.flash-error {
  @include flash($error-color);
  color: #c76d8b;
}

.flash-notice {
  @include flash($blue);
  color: #3568a7;
}

.flash-success {
  @include flash(#417c24);
  color: #417c24;
}
