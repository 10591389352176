/*doc
---
title: Table
name: table
category: Base
---

```html_example
<table class="payment-table">
  <thead>
    <tr>
      <th>Dogs</th>
      <th># of bones</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Golden Retriever</td>
      <td>5</td>
    </tr>
    <tr>
      <td>Pitbull</td>
      <td>2</td>
    </tr>
    <tr>
      <td>Labrador</td>
      <td>1</td>
    </tr>
  </tbody>
  <tfoot>
    <tr class="payment-total">
      <td>Total</td>
      <td>8</td>
    </tr>
  </tfoot>
</table>
```
*/

table {
  -webkit-font-feature-settings: "kern", "liga", "tnum";
  -moz-font-feature-settings: "kern", "liga", "tnum";
  font-feature-settings: "kern", "liga", "tnum";
  border-collapse: collapse;
  margin: $small-spacing 0;
  table-layout: fixed;
  width: 100%;
}

th {
  color: $secondary-font-color;
  padding: $small-spacing 0;
  text-align: left;
  font-weight: $base-font-weight;
}

thead tr {
  border-bottom: $base-border-width solid $medium-gray;
}

tbody tr {
  color: $secondary-font-color;
  font-size: $scaled-down-font-size;
}

td {
  padding: $small-spacing 0;

  &.value {
    width: 25%;
  }
}

tr,
td,
th {
  vertical-align: middle;
}

.payment-table {
  .payment-total {
    border-bottom: none;
    border-top: $base-border-width solid $medium-gray;
    color: $secondary-font-color;
    font-weight: $heavy-font-weight;
  }

  tr {
    th:last-of-type,
    td:last-of-type {
      text-align: right;
    }
  }
}
