.completed_check_ins {
  .primary-content {
    padding-bottom: 80px;
    position: relative;
  }

  .powered-by-kyruus-health-image {
    bottom: 16px;
    position: absolute;
    right: 16px;
  }
}
