/*doc
---
title: Terms
name: terms
category: Base
---

It's required to include terms and agreements before some user actions. We
always want the terms text to be smaller than average so that the user isn't
distracted by it.

```html_example
<p class="accept-terms">
  By tapping “Continue”, I accept the <a href="#terms_of_use">High Five Pact</a>
  and <a href="#privacy_statement">Smoothie Walk Statement</a>.
</p>
<div class="form-actions">
  <button type="submit" class="button ladda-button button-primary">
    Continue
  </button>
</div>
```
*/

.accept-terms {
  color: $secondary-font-color;
  font-size: $note-font-size;
}
