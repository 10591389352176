/*doc
---
title: Dialog
name: dialog
category: Base
---

To display a modal.

```html_example
<div class="dialog">
  <header class="card-header">
    <h1 class="card-title">Header Title</h1>
  </header>
  <p>A paragraph of text</p>
  <div class="form-actions">
    <button>Click me</button>
  </div>
</div>
```
*/

.dialog {
  @extend .card;

  @media #{$only-non-phone-devices} {
    max-width: $max-width;
    padding: $base-spacing;
    width: 90%;

    .card-header {
      padding-top: 0;
    }
  }

  %button,
  button,
  input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    &:not(.agreementButton) {
      display: block;
      float: none;
      margin: 0 auto;
    }
  }
}
