.medical-record-unit {
  &.disclosures {
    border-top: none;
  }

  .add-medical-record-unit:before {
    content: "+ ";
  }

  .field-unit {
    margin-bottom: 0;
    margin-top: $small-spacing;
  }
}

.medical-unit-heading {
  color: $secondary-font-color;
  font-size: $h6-font-size;
  font-weight: $h6-font-weight;
  letter-spacing: $h6-letter-spacing;
}

.medical-unit-detail {
  color: $secondary-font-color;
  margin: 0;
}
