$large-modal-height: 95vh;
$large-modal-width: 95vw;
$modal-margin: -$base-spacing;
$close-icon: "data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' x='0px' y='0px' viewBox='0 0 24 24' style='enable-background:new 0 0 24 24;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill-rule:evenodd;clip-rule:evenodd;fill:%23000000;%7d %3c/style%3e%3cpath class='st0' d='M21,5.2L14.2,12l6.8,6.8c0.6,0.6,0.7,1.7,0.1,2.3c-0.6,0.6-1.6,0.6-2.3-0.1L12,14.2L5.2,21 c-0.6,0.6-1.7,0.7-2.3,0.1c-0.6-0.6-0.6-1.6,0.1-2.3L9.8,12L3,5.2C2.4,4.5,2.3,3.5,2.9,2.9C3.5,2.3,4.5,2.4,5.2,3L12,9.8L18.8,3 c0.6-0.6,1.7-0.7,2.3-0.1C21.7,3.5,21.6,4.5,21,5.2z'/%3e%3c/svg%3e";

.default-modal {
  background: $white;
  border-radius: 16px;
  margin: $base-spacing auto;
  max-width: 30em;
  overflow: hidden;
  padding: $base-spacing;
  position: relative;
  width: auto;

  .card-header {
    border: 0 none;
    height: auto;
    justify-content: center;
    margin: $modal-margin $modal-margin $base-spacing $modal-margin;
    padding: $small-spacing $base-spacing;
  }

  .form-actions {
    border-top: $dashed-border;
    margin: $base-spacing 0 0 0;
    padding-top: $base-spacing;
  }

  .mfp-close {
    border: 0 none;
    padding: 0;
    right: 0.3em;
    top: 0.3em;

    &::after {
      background: url($close-icon) center no-repeat $white;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  &.centered-modal {
    text-align: center;

    .card-header {
      justify-content: center;
    }
  }

  &.wide-modal {
    max-width: 90vw;
  }

  &.pain-diagram-modal {
    max-height: $large-modal-height;
    max-width: $large-modal-width;
    min-height: $large-modal-height;
    min-width: $large-modal-width;
  }

  &.cash-payment-modal {
    padding: $base-spacing;
  }

  &.email-edit-warning-modal {
    .card-header {
      justify-content: center;
    }

    .card-body {
      padding: $extra-small-spacing;
    }

    .form-actions {
      border-top: 0;
      margin: 0;
    }

    .mfp-close {
      display: none;
    }
  }

  h3 {
    color: $secondary-color;
    font-style: italic;
    font-weight: bold;
    margin: 0 0 $small-spacing 0;
  }
}

.contact-inset {
  display: block;
  font-weight: $heavy-font-weight;
  margin-left: $base-spacing;
}

// fix Swipetrack z-index bug http://stackoverflow.com/a/32291924

.mfp-wrap {
  transform: translateZ(1px);
  align-items: center;
  display: flex;
  height: 100% !important;
  justify-content: center;
  overflow: auto;
  position: fixed !important;
  top: 0 !important;
}

.autocomplete-suggestions {
  transform: translateZ(1px);
}

.opened-modal-state {
  height: 100%;
  overflow-y: hidden;
  position: fixed;
  width: 100%;
}
