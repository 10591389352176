/*doc
---
title: Field Hint
name: field-hint
category: Base
---

```html_example
<div class="field-unit">
  <label for="field">Label</label>
  <input id="field" type="text">
  <span class="field-hint">This is hint text under the form field.</span>
</div>
```
*/

.field-hint {
  color: $secondary-font-color;
  display: block;
  font-size: $field-hint-font-size;
  font-weight: $field-hint-font-weight;
  letter-spacing: $field-hint-letter-spacing;
  line-height: $field-hint-line-height;
  margin-left: $field-hint-margin-left;
  margin-top: $field-hint-margin-top;
}
