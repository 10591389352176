.row {
  @media #{$only-non-phone-devices} {
    display: flex;

    .column {
      flex: 1;

      &.form-input {
        margin-right: 1em;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .column-double-wide {
      flex: 2;
    }
  }
}
