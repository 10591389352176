@charset "utf-8";

@import "ladda/css/ladda";
@import "../../../vendor/assets/stylesheets/jquery.signaturepad";
@import "base/base";

@import "magnific-popup/dist/magnific-popup.css";
@import "../../../vendor/assets/stylesheets/jquery-autocomplete";

@import "components/app-header";
@import "components/app-main";
@import "components/card";
@import "components/complete";
@import "components/consents";
@import "components/credit_card_types";
@import "components/dialog";
@import "components/flashes";
@import "components/finding-inputs";
@import "components/forms";
@import "components/grid";
@import "components/heading-fonts";
@import "components/image";
@import "components/location-branding";
@import "components/medical-record-unit";
@import "components/modals";
@import "components/navigation-buttons";
@import "components/notice";
@import "components/pain-assessment";
@import "components/panels";
@import "components/patient-agreement";
@import "components/payment";
@import "components/primary-content";
@import "components/sidebar";
@import "components/swipe_credit_card";
@import "components/terms";
@import "components/check_in_handoff";
@import "components/completed-check-in";
@import "components/current-medications";

@import "form-builder/form-builder";
