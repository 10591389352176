@use "sass:math";

.primary-content {
  flex: 1;
  @extend .card;
  margin: $base-spacing;
  margin-left: $base-spacing * 0.5;

  .primary-content-intro {
    color: $base-font-color;
    margin-bottom: $base-spacing;

    &-important {
      font-weight: bold;
    }
  }

  .review-answer {
    color: $pink;
    display: block;
  }
}

.form-section {
  margin-top: $base-spacing;
  margin-bottom: $base-spacing;

  &-first {
    margin-top: 0;
  }

  .form-section-title {
    color: $base-font-color;
    font-size: $h5-font-size;
    font-weight: $h5-font-weight;
    letter-spacing: $h5-letter-spacing;
    margin: 0 0 var(--unit-spacing) 0;
    text-transform: capitalize;
  }

  .form-section-sub-title {
    font-size: $scaled-down-font-size;
    font-style: italic;
    font-weight: $heavy-font-weight;
    margin-bottom: $small-spacing;
    text-transform: uppercase;
  }
}

.form-section-divider {
  border-bottom: $dashed-border;
  margin: $small-spacing 0;
}

.skip {
  display: inline-block;
  float: right;
  text-align: right;

  svg {
    width: $base-spacing;
    height: math.div($base-spacing, 1.5);
    display: inline-block;
    margin-left: $extra-small-spacing;
    position: relative;
    top: 2px;
  }
}

.explanation {
  color: $secondary-font-color;
  font-style: italic;
  font-weight: $heavy-font-weight;
}
