$signed-icon: "data:image/svg+xml;charset=UTF-8,%3csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 24 22' style='enable-background:new 0 0 24 22;' xml:space='preserve'%3e%3cstyle type='text/css'%3e .st0%7bfill:%231EAE95;%7d %3c/style%3e%3cg%3e%3cg%3e%3cpath class='st0' d='M22,0H12c-1.1,0-2,0.9-2,2v3h2V2h10v7h-3c-0.2,0-0.4,0.1-0.6,0.2L16,11v-1c0-0.6-0.4-1-1-1h-2v2h1v2 c0,0.4,0.2,0.7,0.6,0.9C14.7,14,14.8,14,15,14c0.2,0,0.4-0.1,0.6-0.2l3.7-2.8H22c1.1,0,2-0.9,2-2V2C24,0.9,23.1,0,22,0z M8,14 c2.2,0,4-1.8,4-4c0-2.2-1.8-4-4-4c-2.2,0-4,1.8-4,4C4,12.2,5.8,14,8,14z M8,8c1.1,0,2,0.9,2,2c0,1.1-0.9,2-2,2c-1.1,0-2-0.9-2-2 C6,8.9,6.9,8,8,8z M8,15c-4.7,0-8,2.5-8,6v1h16v-1C16,17.5,12.7,15,8,15z M2.2,20C2.8,18.2,5,17,8,17c3,0,5.2,1.2,5.8,3H2.2z'/%3e%3c/g%3e%3c/g%3e%3c/svg%3e";
$form-message-icon-size: $base-spacing;
$form-message-icon-space: $small-spacing + $form-message-icon-size + $small-spacing;

.form-message {
  border-radius: $base-border-radius;
  padding: $small-spacing $small-spacing $small-spacing $form-message-icon-space;
  position: relative;

  &::before {
    width: $form-message-icon-size;
    height: $form-message-icon-size;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    content: "";
    left: $small-spacing;
    margin-top: -$form-message-icon-size * 0.5;
    position: absolute;
    top: 50%;
  }
}

.info-form-message {
  border: 1px solid $primary-color;
  color: $primary-color;

  &::before {
    background-image: url($signed-icon);
  }
}
