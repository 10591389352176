.disclosures-add-person {
  margin-top: $base-spacing;
}

.patient-agreement-details {
  margin-left: 2.1em;

  .hipaa-copy {
    font-size: $note-font-size;
    font-style: italic;
  }
}

.patient-disclosure-declaration {
  display: block;
  font-style: italic;
  font-weight: $heavy-font-weight;
}

.remove_fields {
  color: $pink;
  display: block;
  margin-top: $base-spacing;
  text-align: right;
}

.consent-content {
  padding-bottom: 2rem;

  p {
    display: block;
    margin: 0;
    padding: 1rem 0;
    white-space: normal;

    &:first-child {
      padding-top: 0;
    }
  }

  ol {
    list-style-type: decimal;
  }

  ol,
  ul {
    margin: 0;
    padding: 0 0 0 1.5em;

    ol,
    ul {
      padding-top: 1rem;
    }
  }

  > {
    ol,
    ul {
      padding-bottom: 1rem;
    }
  }

  li {
    font-size: 1rem;
    margin: 0;
    padding: 0 0 1rem 0.5rem;

    &:last-child {
      padding-bottom: 0;
    }
  }

  ul li {
    list-style-type: disc;
  }

  .consent-content-title {
    font-size: 1.4375rem;
    font-weight: $heavy-font-weight;
    padding-bottom: 1.5rem;

    & + p {
      padding-top: 0;
    }
  }

  .consent-question {
    padding-top: 2rem;
    white-space: nowrap;
  }

  .consent-answer {
    font-size: 1.25rem;
    font-weight: $heavy-font-weight;
    padding-left: 2rem;
  }

  .consent-patient-question {
    display: block;
    font-size: 1rem;
    white-space: nowrap;
  }

  .consent-patient-answer {
    font-weight: 700;
  }
}
