button:not([class*="Mui"]),
input[type="button"],
input[type="reset"],
input[type="submit"],
.button {
  -webkit-appearance: none;
  -moz-appearance: none;
  align-items: center;
  appearance: none;
  background-color: $primary-color;
  border: $base-border-width solid $primary-color;
  border-radius: 4px;
  color: var(--white);
  cursor: pointer;
  display: inline-flex;
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  font-size: $base-font-size;
  font-weight: $heavy-font-weight;
  height: 3.15rem;
  justify-content: center;
  letter-spacing: 1px;
  line-height: 1.3684rem;
  outline: none;
  padding: 6px 16px;
  text-decoration: none;
  transition:
    background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:not(.agreementButton) {
    text-transform: capitalize;
  }

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    &:not(.agreementButton) {
      background-color: $button-hover-color;
      border-color: $button-hover-color;
      color: var(--white);
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
    position: relative;
  }

  &.button-primary.ladda-button {
    .ladda-spinner {
      right: 50%;
      transition: none;
      width: 0;
    }

    &[data-loading]:disabled {
      background: rgba(0, 0, 0, 0.12);
      border: 0px none;
      color: transparent;
      padding-right: 16px;
    }
  }

  &.button-secondary {
    background: var(--white);
    border: $base-border-width solid $primary-color;
    color: $primary-color;

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      background-color: $button-hover-color;
      border-color: $button-hover-color;
      color: var(--white);
    }

    &.button-default {
      background: var(--white);
      color: $primary-color;
    }
  }

  &.button-tertiary {
    background-color: $primary-color;
    border: 0;
    color: var(--white);
    font-size: $scaled-down-font-size;

    &:focus,
    &:hover {
      background-color: #3bccb2;
    }
  }

  &.button-default {
    background: $primary-color;
    border: $base-border-width solid $primary-color;
    color: var(--white);

    &:focus:not(:disabled),
    &:hover:not(:disabled) {
      background-color: $button-hover-color;
      border-color: $button-hover-color;
      color: var(--white);
    }
  }

  &.as-link {
    -webkit-transition: color 0.1s linear;
    -moz-transition: color 0.1s linear;
    transition: color 0.1s linear;
    background: none;
    border: 0;
    clear: both;
    color: $base-link-color;
    font-weight: $heavy-font-weight;
    letter-spacing: initial;
    line-height: inherit;
    padding: 0;
    text-decoration: none;
    text-transform: none;
    vertical-align: initial;

    &:hover,
    &:hover:not(:disabled) {
      background: none;
      color: #269480;
    }

    &:active,
    &:focus,
    &:active:not(:disabled),
    &:focus:not(:disabled) {
      background: none;
      color: #269480;
      outline: none;
    }
  }

  &.language {
    position: absolute;
    top: $base-spacing;
    right: $base-spacing;
  }
}

.link-as-text {
  color: $base-font-color;
}
