.pain-diagram-thumbnail {
  position: relative;
  text-align: center;
  padding: 0;

  .thumbnail-img {
    background-size: 450px;
    width: 450px;
  }
}

.pain-diagram-thumbnail .thumbnail-img,
.pain-diagram .drawing-area {
  background-repeat: no-repeat;
  background-size: cover;

  &.pain-assessment-full-body {
    background-image: url("~images/pain_people.svg");
  }

  &.pain-assessment-foot-and-ankle {
    background-image: url("~images/foot_and_ankle.svg");
  }

  &.pain-assessment-full-body-peg {
    background-image: url("~images/pain_people.svg");
  }
}

.pain-diagram .drawing-area {
  border: $base-border-width solid $dark-gray;
  border-radius: $base-border-radius;
}

.edit-pain-diagram {
  font-size: $note-font-size;
  left: 1em;
  position: absolute;
  top: 1em;
}

.question-emphasis {
  text-decoration: underline;
}
