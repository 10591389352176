$card-image-size: 284px;
$card-image-bg: #f4f4f4;
$card-image-border-color: #d7d9d9;
$sending-message-height: $base-font-size * 2.5;
$sending-message-animation-duration: 4s;

@keyframes wait {
  0% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes waitShow {
  0% {
    opacity: 0;
  }
  85% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.swipe-card-modal {
  .send-card-data-success .button-secondary {
    float: left;
  }

  .email-field {
    margin-bottom: $base-spacing;
  }

  .sending-message {
    height: $sending-message-height;
    position: relative;

    p {
      height: $sending-message-height;
      position: absolute;
      width: 100%;
    }

    .sending-message-regular {
      animation-duration: $sending-message-animation-duration;
      animation-name: wait;
      line-height: $sending-message-height;
      opacity: 0;
    }

    .sending-message-long {
      animation-duration: $sending-message-animation-duration;
      animation-name: waitShow;
    }
  }

  .swipe-not-detected-image-container {
    margin-bottom: $default-spacing;
  }

  .swipe-not-detected-text {
    margin-bottom: $base-line-height;
  }
}

.payment-swipe-image-container {
  background-color: $card-image-bg;
  border: 1px dashed $card-image-border-color;
  border-radius: $base-border-radius;
  margin-bottom: $base-spacing;
  padding: $base-spacing 0;
}

.payment-swipe-image {
  height: $card-image-size;
  margin: 0 auto;
  position: relative;
  width: $card-image-size;

  &.orientation-90 img {
    transform: rotate(90deg);
  }

  &.orientation180 img {
    transform: rotate(180deg);
  }

  &.orientation90 img {
    transform: rotate(-90deg);
  }

  img {
    height: 100%;
    left: 0;
    padding-left: 65px;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    width: 100%;
  }
}

.payment-error-message {
  background: $pink;
  color: $white;
  padding: 10px;
}
