/*doc
---
title: Styled Select
name: styled-select
category: Base
---

We use selects for multiple-choice single-answer inputs that have seven or more
options. We have created a custom style for our select inputs to ensure a large
enough clickable target for our users.

```html_example
<div class="field-unit">
  <label for="pies">Pies</label>
  <div class="styled-select">
    <select id="pies" name="pies">
      <option value="" disabled selected>Select</option>
      <option value="apple">Apple</option>
      <option value="peach">Peach</option>
      <option value="pear-crumble">Pear Crumble</option>
      <option value="strawberry-rhubarb">Strawberry Rhubarb</option>
    </select>
  </div>
</div>
```

_Error styling:_

```html_example
<div class="field-unit field-with-errors">
  <label for="pies">Pies</label>
  <span class="field-error">Too bad you can only pick one.</span>
  <div class="styled-select">
    <select id="pies" name="pies">
      <option value="" disabled selected>Choose wisely my friend</option>
      <option value="apple">Apple</option>
      <option value="peach">Peach</option>
      <option value="pear-crumble">Pear Crumble</option>
      <option value="strawberry-rhubarb">Strawberry Rhubarb</option>
    </select>
  </div>
</div>
```
*/
$icon-select-arrow: "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' focusable='false' aria-hidden='true' viewBox='0 0 22 24'%3E%3Cpath d='M7 10l3 3 3-3z' fill='rgba(0, 0, 0, 0.54)'%3E%3C/path%3E%3C/svg%3E";

.styled-select {
  background: url($icon-select-arrow);
  background-color: $white;
  background-position: right center;
  background-repeat: no-repeat;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;

  select {
    appearance: none;
    background-color: transparent;
    background-image: none;
    border: $input-border;
    border-radius: $base-border-radius;
    box-shadow: none;
    color: currentColor;
    cursor: pointer;
    font-family: $base-font-family;
    margin: 0;
    padding: $input-padding;
    padding-right: 30px;
    width: 100%;

    &:hover {
      border-color: $input-border-hover-color;
    }

    &:focus {
      border-color: $input-border-active-color;
      border-width: 2px;
      outline: none;
      padding: 7.5px 29px 7.5px 13px;
    }

    &:disabled {
      border-color: $input-disabled-color;
      color: $input-disabled-color;
      outline: none;
    }
  }
}
