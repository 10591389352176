@use "sass:math";

$balance-hint-divider: 1.25;
$card-image-size: 284px;
$card-image-bg: #f4f4f4;
$card-header-error-color: #cb3962;
$card-image-size: 150px;

.view-details {
  display: block;
  font-size: $scaled-down-font-size;
  font-weight: $heavy-font-weight;
}

.payment-plans {
  margin-top: -$small-spacing;
  text-align: right;
}

.cvv-images {
  text-align: center;

  > :first-child {
    margin-right: 1em;
  }
}

.billing-address {
  margin-left: $collection-indent;
  display: block;
  font-weight: $heavy-font-weight;
}

.payment-summary {
  position: relative;

  .card-relief {
    margin-bottom: 0.5em;
  }

  .outstanding-balance-hint {
    font-size: math.div($base-font-size, $balance-hint-divider);
    font-style: italic;
    margin-left: 5em * $balance-hint-divider;
  }
}

.payment-summary-total {
  background: $secondary-color;
  border-radius: 15px;
  color: $white;
  padding: 1em;
  position: absolute;
  text-align: center;

  &.big-margin {
    top: 35px;
  }

  &.small-margin {
    top: 10px;
  }

  span {
    font-size: 1.6em;
  }
}

.claim-hint {
  white-space: nowrap;
}

.payment-swipe-image-container {
  background-color: $card-image-bg;
  border: $dashed-border;
  border-radius: $base-border-radius;
  margin-bottom: $base-spacing;
  padding: $base-spacing 0;
}

.payment-swipe-image {
  height: $card-image-size;
  margin: 0 auto;
  position: relative;
  width: $card-image-size;

  &.orientation-90 img {
    transform: rotate(90deg);
  }

  &.orientation180 img {
    transform: rotate(180deg);
  }

  &.orientation90 img {
    transform: rotate(-90deg);
  }

  img {
    height: 100%;
    left: 0;
    padding-left: 65px;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    width: 100%;
  }
}

.cash-payment-link {
  padding: $base-spacing 0;
}

.card-header.card-reader-error {
  background-color: $card-header-error-color;
}

.card-reader-error-image {
  height: $card-image-size;
  margin-bottom: $base-spacing;
  width: $card-image-size;
}

.swipe-card-body .payment-icon-button {
  background-color: transparent;
  border: 0;
  height: auto;
  padding: 0;
  transition: none;

  &:focus:not(:disabled),
  &:hover:not(:disabled) {
    &:not(.agreementButton) {
      background-color: transparent;
    }
  }
}

.you-owe {
  font-size: $h6-font-size;
  font-weight: $h6-font-weight;
  letter-spacing: $h6-letter-spacing;
}
