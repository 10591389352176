.field-unit {
  flex: 1;
  margin-bottom: $base-spacing * 1.25;
}

.block-field-group,
.inline-field-group {
  .field-unit {
    margin: $small-spacing;
  }
}

.block-field-group,
.inline-field-group.group-with-errors {
  border: $base-border;
  border-radius: $base-border-radius;
  margin-bottom: $base-spacing;

  > label {
    margin-top: $small-spacing;
    margin-left: $small-spacing;
  }
}

.inline-field-group {
  display: flex;
  align-items: flex-start;

  .field-unit {
    margin-right: 0;

    &:first-child {
      margin-left: 0;
    }
  }

  &.group-with-errors {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-color: $error-color;

    .field-unit {
      &:first-child {
        margin-left: $small-spacing;
      }

      &:last-child {
        margin-right: $small-spacing;
      }
    }
  }
}

.inline-text-input {
  display: inline-block;
  margin-right: 15px;
  width: 7em;

  input {
    margin-top: -0.4em;
    margin-left: 0.5em;
    display: inline-block;
    vertical-align: top;
  }
}

.sub-section-form {
  border-left: 2px dashed $primary-color;
  margin-left: $small-spacing;
  padding-left: $default-spacing * 2;
  padding-top: $small-spacing;
}

.has-sub-section {
  margin-bottom: $base-spacing;

  .field-unit:last-child,
  .checkable-collection {
    margin-bottom: 0;
  }
}
