$right-arrow: "data:image/svg+xml;charset=utf-8,<svg width='21' height='17' viewBox='0 0 21 17' xmlns='http://www.w3.org/2000/svg'><title>a</title><g fill='none' fill-rule='evenodd'><g fill='#ffffff'><g><g><path d='M13.456.54c-.134-.15-.31-.225-.486-.225s-.352.075-.487.225c-.268.3-.268.766 0 1.067L17.63 7.34H.815c-.38 0-.688.346-.688.77 0 .426.308.77.688.77h17.108l-5.438 6.107c-.268.3-.268.8 0 1.1.268.3.704.306.973.005l6.467-7.232c.13-.144.202-.34.202-.543 0-.204-.072-.4-.202-.543L13.456.54z'/></g></g></g></g></svg>";
$camera: "data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 298 298' width='512' height='512'%3e%3cg fill='%23fff'%3e%3cpath d='M149 130.417c-18.013 0-32.667 14.654-32.667 32.666 0 18.013 14.654 32.667 32.667 32.667s32.667-14.654 32.667-32.667c0-18.012-14.654-32.666-32.667-32.666z'/%3e%3cpath d='M273.25 64.083H264v-3.417c0-13.669-11.081-24.75-24.75-24.75h-65.5c-13.669 0-24.75 11.081-24.75 24.75v3.417H24.75C11.081 64.083 0 75.164 0 88.834v148.5c0 13.669 11.081 24.75 24.75 24.75h248.5c13.669 0 24.75-11.081 24.75-24.75v-148.5c0-13.67-11.081-24.751-24.75-24.751zM45.928 130.417c-11.046 0-20-8.954-20-20s8.954-20 20-20 20 8.954 20 20c0 11.045-8.954 20-20 20zM149 235.75c-40.068 0-72.667-32.599-72.667-72.667S108.932 90.417 149 90.417s72.667 32.598 72.667 72.666S189.068 235.75 149 235.75z'/%3e%3c/g%3e%3c/svg%3e";

%icon-button {
  padding: $extra-small-spacing;
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  content: "";
  float: right;
  margin-left: $small-spacing;
}

.navigation-button {
  background-color: $light-gray;
  display: inline-block;
  margin-bottom: $base-spacing;
  padding: $small-spacing;
  width: 100%;

  &.forward::before {
    @extend %icon-button;
    background-image: url($right-arrow);
    background-color: $primary-color;
    background-size: 25px;
  }

  &.add-image::before {
    @extend %icon-button;
    background-color: $primary-color;
    background-image: url($camera);
    background-size: 35px;
  }

  + .hidden {
    display: none;
  }
}
